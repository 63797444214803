<template>
	<div>
		<el-row>
			<el-col>
				<el-form :inline='true'>
					<el-form-item label="">
						<el-select v-model="filters.ddCommonStatus" placeholder="启用状态" filterable clearable>
							<el-option
									v-for="item in areaArr"
									:key="item.id"
									:label="item.name"
									:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<!-- 列表 -->
		<el-table
				:data="tableData"
				border
				style="width: 100%"
				v-loading="listLoading"
				ref="multipleTable"
				@current-change='currentChange'
				:highlight-current-row='true'
		>
			<el-table-column type='index' label="序号" width='80' align="center" :index='indexMethod'></el-table-column>
			<el-table-column prop="brandName" label="品牌名称"  width='' align="center" ></el-table-column>
			<el-table-column prop="brandCode" label="品牌编号"  width='' align="center" ></el-table-column>
			<el-table-column prop="createTime" label="创建时间"  width='' align="center" ></el-table-column>
			<el-table-column prop="ddCommonStatus" label="启用状态"  width='110' align="center" >
				<template #default='scope'>
					<el-tag v-if='scope.row.ddCommonStatus === 1' type='success'>启用</el-tag>
					<el-tag v-else type='warning'>禁用</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="updateTime" label="更新时间" width='' align="center" ></el-table-column>
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar">
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pages.pageIndex"
					:page-sizes="pages.pageArr"
					:page-size="pages.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pages.dataCount">
			</el-pagination>
		</el-col>
		<el-dialog
				:title= "editFalse?'编辑':'添加品牌'"
				:visible.sync="dialogs.addType.dialog"
				width="40%"
				:close-on-click-modal='false'
		>
			<el-form :model="addForm"  ref="addFormRef" label-width="140px">
				<el-form-item label="品牌名称：" >
					<el-input style="width: 80%"  v-model="addForm.brandName" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="品牌编码：" >
					<el-input style="width: 80%"  v-model="addForm.brandCode" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="启用状态：">
					<el-select v-model="addForm.ddCommonStatus" placeholder="启用状态" filterable clearable>
						<el-option
								v-for="item in areaArr"
								:key="item.id"
								:label="item.name"
								:value="item.id"
						>
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div v-if="!editFalse" slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="addConfirm" :loading="loading">确认</el-button>
            </div>
			<div v-else slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="showConfirm" :loading="loading">确认</el-button>
            </div>
		</el-dialog>
		
		
	</div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
	uploadVideoFile,
	getAreaDictionaryList,
	getExamQuestionList,
	getOproomProfessionDirList,
	getOproomProfessionFuncList,
	updateOproomProfessionDir,
	getPointListByProDir,
	addExamQuestions,
	updateExamQuestion,
	addVideoByCode,
	getVideoUploadParams,
	getOproomBrandList,
	addOproomBrand, updateOproomBrand
} from '../../api/api'
import PlvVideoUpload from "@polyv/vod-upload-js-sdk";

export default {
	components:{Toolbar},
	data() {
		return {
			buttonList:[],
			tableData: [],
			tableData1: [],
			registerType:[],
			areaArr: [
				{name:'启用',id:1},
				{name:'禁用',id:0},
			],
			listLoading: false,
			//关于分页的obj
			pages: {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			filters: {
				ddCommonStatus: undefined,
			},
			dialogs:{
				addType:{
					dialog:false,
					confirm:false,
					firurl:false,
				},
				confirm:false,
			},
			addForm:{
				brandName:undefined,
				ddCommonStatus:undefined,
				brandCode:undefined,
			},
			loading:false,
			editFalse:false,
		}
	},
	methods: {
		
		handleAdd(){
			this.addForm={
				brandName:undefined,
				ddCommonStatus:undefined,
				brandCode:undefined,
			}
			this.editFalse = false
			this.dialogs.addType.dialog = true;
		},
		changeVal(val){
			console.log(val,'val112222')
			let params = {
				oproomProfessionDirId:val,
			}
			getPointListByProDir(params).then(res=>{
				if (res.data.success){
					this.pointNums = res.data.response
				}
			})
		},
		rowClass () {
			return 'padding:8px!important;'
		},
		callFunction(item) {
			this[item.func].apply(this, item);
		},
		getDataList(){
			this.pages = {
				pageIndex: 1,
				pageSize: 20,
			}
			this.getListData()
		},
		// 列表查询接口
		getListData(){
			let params = {
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				ddCommonStatus: this.filters.ddCommonStatus
			}
			this.listLoading = true
			getOproomBrandList(params).then(res => {
				let result = res.data
				if(result.success) {
					this.tableData = result.response.data
					this.pages.dataCount = result.response.dataCount
				}
				this.listLoading = false
			})
		},
		async addConfirm(){
			let params = {
				...this.addForm,
			}
			const res = await addOproomBrand(params)
			if (res.data.success){
				this.getDataList()
				this.dialogs.addType.dialog = false ;
				this.$message({
					message:res.data.message,
					type:'success'
				})
			}else{
				this.$message({
					message:res.data.message,
					type:'warning'
				})
			}
		},
		
		handleEdit(){
			if (!this.needData){
				this.$message({
					message:'请选择一条数据',
					type:'warning'
				})
				return;
			}
			this.editFalse = true;
			this.addForm = {
				...this.needData,
			}
			
			this.dialogs.addType.dialog = true;
		},
		showConfirm() {
				this.editConfirm()
		},
		async editConfirm(){
			let params = {
				...this.addForm,
				id:this.needData.id,
			}
			const res = await updateOproomBrand(params)
			if (res.data.success){
				this.getDataList()
				this.dialogs.addType.dialog = false ;
				this.dialogs.addType.confirm = false ;
				this.$message({
					message:res.data.message,
					type:'success'
				})
			}else{
				this.$message({
					message:res.data.message,
					type:'warning'
				})
			}
		},
		//删除
		async delete(){
			if (!this.needData){
				this.$message({
					message:'请选择一条数据',
					type:'warning'
				})
				return;
			}
			this.addForm = {
				...this.needData,
			}
			let params = {
				...this.addForm,
				ddCommonStatus:2,//状态，1:启用;2:禁用
			}
			const res = await updateExamQuestion(params)
			if (res.data.success){
				this.getDataList()
				this.dialogs.addType.dialog = false ;
				this.$message({
					message:res.data.message,
					type:'success'
				})
			}else{
				this.$message({
					message:res.data.message,
					type:'warning'
				})
			}
			
		},
		
		
		// // 获取省
		// getAreaList() {
		//   var params = {
		//     parentId: 1,
		//   }
		//   getAreaDictionaryList(params).then(res => {
		//     if (res.data.success) {
		//       this.areaArr = res.data.response.reverse();
		//     }
		//   })
		// },
		//获取字符空间
		async getNamespace(){
			let params = {
				pageIndex:undefined,
				pageSize:undefined,
			}
			const res = await  getByNamespace(params)
			console.log(res,'res')
		},
		
		
		// 点击table某一行
		currentChange(val) {
			this.needData = val
			console.log(val,'val')
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getListData();
		},
		handleSizeChange(val){
			this.pages.pageSize = val;
			this.getListData();
		},
		// 时间格式化
		formatCreateTime: function(row, column) {
			return !row || row == ""
					? ""
					: util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
		},
		// 表格数据分页条数累计
		indexMethod(index) {
			var currentPage = this.pages.pageIndex
			var pageSize = this.pages.pageSize
			return (index + 1) + (currentPage - 1) * pageSize
		},
	},
	watch: {
	
	},
	created(){
		this.utils = util
		let routers = window.localStorage.router
				? JSON.parse(window.localStorage.router)
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
		this.uploadFile = uploadVideoFile
		this.videoUpload = new PlvVideoUpload({
			region: 'line1', // (可选)上传线路, 默认line1
			events: {
				Error: (err) => { // 错误事件回调
					console.log(err);
					let errMag = `（错误代码：${err.code}）${err.message}`;
					this.$alert(errMag, '标题名称', {
						confirmButtonText: '确定',
						type: 'error',
					});
				},
				UploadComplete: () => { // 全部上传任务完成回调
					console.info('上传结束：', this.videoUpload);
					console.log(this.tableData)
					this.$message({
						message: '全部上传任务完成',
						type: 'success'
					});
				}
			}
		});
		//职业等级方向
		let params2 = {
			pageIndex:1,
			pageSize:20,
			ddCommonStatus:1,
		}
		getOproomProfessionDirList(params2).then(res2=>{
			if (res2.data.success){
				this.registers = res2.data.response.data
			}
		})
		this.getListData();
		// this.getAreaList()
	},
	computed:{
		
		headers(){
			return {
				"Authorization": 'Bearer ' + localStorage.getItem("Token")
			}
		}
		
	},
	beforeDestroy() {
		clearInterval(this.timer);
	},
	mounted() {
		// this.autoUpdateUserData(null, this.videoUpload);
	},
}
</script>

<style lang="stylus" scoped>
.projectName {
	text-align: center;
}
.projectSelect {
	margin-bottom: 15px;
}
.projectTitle {
	margin-bottom: 15px
}
.replyBtn {
	margin: 10px 0;
	color: #409eff;
	cursor: pointer;
	width: 30px;
}
.replyMessage {
	background: #eee;
	line-height: 30px;
	padding: 5px;
	border-radius: 2px;
	margin: 5px 15px;
}
.titleTimer {
	margin-left: 10px;
}
.green_t {
	color: green;
}
.red_t {
	color: red;
}
</style>
